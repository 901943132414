import React from "react"
import Layout from "../../components/Layout"
import Services from "../../components/Services"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Banner } from "../../components/Banner"
import EducationOffers from "../../components/education/EducationOffers"
import SEO from "../../components/seo"
import SectionTitle from "../../components/SectionTitle"

export default function Bespoke() {
  const data = useStaticQuery(graphql`
    query educationBespoke {
      Security: file(
        relativePath: { eq: "services/AdobeStock_155988837.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      SafeSystems: file(
        relativePath: { eq: "services/AdobeStock_296112197.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      EnergyEfficiency: file(
        relativePath: { eq: "services/AdobeStock_133113660.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      VendingMachines: file(
        relativePath: { eq: "services/AdobeStock_140133162.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      CPDSolutions: file(
        relativePath: { eq: "services/AdobeStock_370986001.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Catering: file(
        relativePath: { eq: "services/AdobeStock_269267723.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      WhiteGoods: file(
        relativePath: { eq: "services/AdobeStock_329321995.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Communication: file(
        relativePath: { eq: "services/AdobeStock_283777534.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      PerformingArts: file(
        relativePath: { eq: "services/AdobeStock_841617.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      OutdoorEquipment: file(
        relativePath: { eq: "services/AdobeStock_215501445.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      MaintenanceEquipment: file(
        relativePath: { eq: "services/AdobeStock_204617538.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      DesignTechnology: file(
        relativePath: { eq: "services/AdobeStock_294027051.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      SportsEquipment: file(
        relativePath: { eq: "services/AdobeStock_267465207.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      MusicalEquipment: file(
        relativePath: { eq: "services/AdobeStock_313935766.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Science: file(
        relativePath: { eq: "services/AdobeStock_190532441.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      EarlyYears: file(
        relativePath: { eq: "services/AdobeStock_151209871.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Security",
      description:
        "CCTV, Visitor Management Systems, Registration Systems, Bicycle Storage",
      image: data.Security.childImageSharp.fixed,
    },
    {
      title: "Safety Systems",
      description:
        "Fire Alarm Systems, Sprinkler Systems, Access Control, PA Systems, Defibrillators",
      image: data.SafeSystems.childImageSharp.fixed,
    },
    {
      title: "Energy Efficiency",
      description: "LED Lighting, Hand Dryers, Biomass Boilers, Solar Panels",
      image: data.EnergyEfficiency.childImageSharp.fixed,
    },
    {
      title: "Vending Machines",
      description:
        "Snack Machines, Hot Drinks Machines, Bottle Machines, Combi Machines, Water Coolers",
      image: data.VendingMachines.childImageSharp.fixed,
    },
    {
      title: "CPD Solutions",
      description:
        "Lesson Observation, Retrospective Cameras, Software Platforms",
      image: data.CPDSolutions.childImageSharp.fixed,
    },
    {
      title: "Catering",
      description:
        "Cashless Catering Equipment, Biometric Catering Equipment, Commercial Kitchen Equipment, Outdoor Food Kiosks, Food Technology Refurbishment",
      image: data.Catering.childImageSharp.fixed,
    },
    {
      title: "White Goods",
      description:
        "Washing Machines, Tumble Dryers, Refrigeration, Dishwashers",
      image: data.WhiteGoods.childImageSharp.fixed,
    },
    {
      title: "Communication Systems",
      description:
        "Telephone Systems, Answerphone Solutions, School Radio Solution, Internal Phone System, Internal Intercom",
      image: data.Communication.childImageSharp.fixed,
    },
    {
      title: "Performing Arts",
      description:
        "Staging, Lighting, Green Screen Solutions, Theatre Seating, Sound Systems",
      image: data.PerformingArts.childImageSharp.fixed,
    },
    {
      title: "Outdoor Equipment",
      description:
        "Playgrounds, Canopies, Prefabricated Building, Outdoor Gym Equipment, Surfacing",
      image: data.OutdoorEquipment.childImageSharp.fixed,
    },
    {
      title: "Maintenance Equipment",
      description:
        "Floor Scrubbers, Cleaning Machines, Lawnmowers, Walk-Behind Floor Sweeper",
      image: data.MaintenanceEquipment.childImageSharp.fixed,
    },
    {
      title: "Design Technology",
      description:
        "Laser Cutters, Pillar Drills, Workbenches, Lathes, 3D Printers, Extraction",
      image: data.DesignTechnology.childImageSharp.fixed,
    },
    {
      title: "Sports Equipment",
      description:
        "Gym Equipment and Flooring, Electronic Basketball Nets, Cricket Nets, Astroturf Pitches, Floodlights",
      image: data.SportsEquipment.childImageSharp.fixed,
    },
    {
      title: "Musical Equipment",
      description:
        "Pianos, Instruments, Mac Music Suites, Sound Systems, Music Software",
      image: data.MusicalEquipment.childImageSharp.fixed,
    },
    {
      title: "Science Laboratories",
      description:
        "Microscopes, Workbenches, Long-life Experiment Equipment, Autoclave, Fume Cupboard",
      image: data.Science.childImageSharp.fixed,
    },
    {
      title: "Early Years",
      description:
        "Sensory Rooms, Soft Seating, Furniture, Interactive Touch Tables",
      image: data.EarlyYears.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Bespoke Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="We meet all your school computer leasing and education finance
                  requirements"
              title="Bespoke School Leasing Solutions"
              description={
                <>
                  <p>
                    The Compulease™ service doesn’t start and end with
                    “technology” solutions. In fact, when it boils down to it,
                    we can provide a leasing solution for just about any
                    equipment solution for your school, college or university.
                  </p>
                  <p>
                    Below, you’ll find a whole array of equipment ideas which
                    may benefit your school. Whatever equipment you’re looking
                    for, our friendly team of experts will be able to provide
                    either a “supply only” or a full “design to implementation”
                    solution.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
        <Services title="Solutions we provide" services={services} />
        <Margin>
          <Banner
            image={data.BannerImage.childImageSharp.fluid}
            title="Speak to our friendly customer service team
        for all your school computer leasing and
        education finance needs"
            link={{
              text: "Get in touch",
              to: "/education/ContactUs/",
            }}
          />
        </Margin>
      </Container>
    </Layout>
  )
}

const Margin = styled.div`
  margin: 4rem 0;
`
